<script setup>
import {useBaseStore} from "~/stores/base";
// 引入swiper组件
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation, Pagination, Autoplay} from 'swiper/modules';
import {PATH_PRODUCT} from "~/utils/constants";
import _ from "lodash"
import {wishlistAdd, wishlistDel} from "~/api/api.wishlist";
import {toLogin} from "~/utils/common";
import {tiktokPixelTrack} from "~/utils/tiktok-track";
import {ELEMENT_TYPE, formatElementLabel} from "~/utils/smartlook";

const debounce = _.debounce((params, func) => {
  func(params);
}, 300);
const router = useRouter()

/** props **/
const props = defineProps({
  title: {
    default: "",
    required: true
  },
  list: {
    default: () => [],
    required: true
  },
  btnViewAllVisible: {
    default: false
  },
  btnViewAllLink: {
    default: ""
  }
})

const currentHover = ref(-1)

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const isLogin = computed(() => useBaseStore().getterIsLogin)

/** methods **/
const handleLike = (item) => {
  if (!isLogin.value) return toLogin()
  item.isFollow = !item.isFollow
  debounce({}, async () => {
    const shopProductId = item.shopProductId
    if (item.isFollow) {
      await wishlistAdd({shopProductId})
      tiktokPixelTrack("AddToWishlist", {content_id: shopProductId})
    } else {
      await wishlistDel({shopProductId})
    }
  })
}
const handleToProduct = (item) => {
  router.push(`${PATH_PRODUCT}/${item.shopProductId}/${item.handle}`)
}
const onSwiper = (swiper) => {
};
const onSlideChange = () => {
};
const handleMouseenter = (index) => {
  currentHover.value = index
}
const handleMouseleave = () => {
  currentHover.value = -1
}
</script>

<template>
  <div class="module-carousel-count-6-mobile" v-if="isMobile">
    <div class="title">{{ props.title }}</div>
    <div class="carousel">
      <div class="item" v-for="(item, index) in list">
        <div
          class="item-top"
          :style="{backgroundImage: `url(${item.coverList.find(cover => cover.type === 'main')?.url})`}"
          @click="handleToProduct(item)"
          element-async-observe
          element-expose-observe
          element-click-observe
          :element-type="ELEMENT_TYPE.PRODUCT_CARD"
          :element-title="`${item.brand} - ${item.title}`"
          :element-label="formatElementLabel(title)"
          :element-params="`${PATH_PRODUCT}/${item.shopProductId}/${item.handle}`"
          :element-index="index"
        >
          <!-- 喜欢 -->
          <div
            class="like"
            @click.stop="handleLike(item)"
            element-async-observe
            element-click-observe
            :element-type="ELEMENT_TYPE.BUTTON"
            :element-title="`${item.brand} - ${item.title}`"
            :element-label="item.isFollow ? ELEMENT_LABEL.LIKE : ELEMENT_LABEL.UNLIKE"
            :element-params="`productId=${item.shopProductId}`"
            :element-index="index"
          >
            <img v-show="item.isFollow" src="@/assets/img/icon-liked.svg" alt="icon">
            <img v-show="!item.isFollow" src="@/assets/img/icon-unlike.svg" alt="icon">
          </div>
        </div>
        <nuxt-link
          :to="`${PATH_PRODUCT}/${item.shopProductId}/${item.handle}`"
          class="item-bottom"
          element-async-observe
          element-click-observe
          :element-type="ELEMENT_TYPE.PRODUCT_CARD"
          :element-title="`${item.brand} - ${item.title}`"
          :element-label="formatElementLabel(title)"
          :element-params="`${PATH_PRODUCT}/${item.shopProductId}/${item.handle}`"
          :element-index="index"
        >
          <div class="brand">{{ item.brand }}</div>
          <div class="name">{{ item.title }}</div>
          <div class="price">Retail: ${{ item.price }} <img v-if="item.premium" class="premium"
                                                            src="@/assets/img/icon-diamond.svg"
                                                            alt="diamond"></div>
        </nuxt-link>
      </div>
    </div>
    <nuxt-link :to="btnViewAllLink" class="btn-secondary" v-if="btnViewAllVisible">
      view all
    </nuxt-link>
  </div>
  <div class="module-carousel-count-6-desktop" v-else>
    <div class="title">{{ props.title }}</div>
    <div class="carousel">
      <swiper
        loop
        grab-cursor
        :autoplay="false"
        :navigation="{
          prevEl: '.navigation-left',
          nextEl: '.navigation-right'
        }"
        :modules="[Navigation, Pagination, Autoplay]"
        :slides-per-view="6"
        :slides-per-group="6"
        :space-between="10"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide v-for="(item, index) in list">
          <div
            class="slide-top"
            @click="handleToProduct(item)"
            @mouseenter="handleMouseenter(index)"
            @mouseleave="handleMouseleave"
            element-async-observe
            element-expose-observe
            element-click-observe
            :element-type="ELEMENT_TYPE.PRODUCT_CARD"
            :element-title="`${item.brand} - ${item.title}`"
            :element-label="formatElementLabel(title)"
            :element-params="`${PATH_PRODUCT}/${item.shopProductId}/${item.handle}`"
            :element-index="index"
          >

            <!-- 图+视频 -->
            <template v-if="item.video.url">
              <img v-show="currentHover !== index" class="cover-img"
                   :src="item.coverList.find(cover => cover.type === 'main')?.url" alt="cover">
              <video v-show="currentHover === index" class="cover-video"
                     :poster="item.coverList.find(cover => cover.type === 'main')?.url" autoplay muted loop>
                <source :src="item.video.url" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </template>
            <!-- 双图 -->
            <template v-else-if="item.coverList.length === 2">
              <img v-show="currentHover !== index" class="cover-img"
                   :src="item.coverList.find(cover => cover.type === 'main')?.url" alt="cover">
              <img v-show="currentHover === index" class="cover-img"
                   :src="item.coverList.find(cover => cover.type === 'hover')?.url" alt="cover">
            </template>
            <!-- 单图 -->
            <img v-else class="cover-img" :src="item.coverList.find(cover => cover.type === 'main')?.url" alt="cover">

            <!-- 喜欢 -->
            <div
              class="like"
              @click.stop="handleLike(item)"
              element-async-observe
              element-click-observe
              :element-type="ELEMENT_TYPE.BUTTON"
              :element-title="`${item.brand} - ${item.title}`"
              :element-label="item.isFollow ? ELEMENT_LABEL.LIKE : ELEMENT_LABEL.UNLIKE"
              :element-params="`productId=${item.shopProductId}`"
              :element-index="index"
            >
              <img v-show="item.isFollow" src="@/assets/img/icon-liked.svg" alt="icon">
              <img v-show="!item.isFollow" src="@/assets/img/icon-unlike.svg" alt="icon">
            </div>
          </div>
          <nuxt-link
            :to="`${PATH_PRODUCT}/${item.shopProductId}/${item.handle}`"
            class="slide-bottom"
            element-async-observe
            element-click-observe
            :element-type="ELEMENT_TYPE.PRODUCT_CARD"
            :element-title="`${item.brand} - ${item.title}`"
            :element-label="formatElementLabel(title)"
            :element-params="`${PATH_PRODUCT}/${item.shopProductId}/${item.handle}`"
            :element-index="index"
          >
            <div class="brand">{{ item.brand }}</div>
            <div class="name">{{ item.title }}</div>
            <div class="price">Retail: ${{ item.price }} <img v-if="item.premium" class="premium"
                                                              src="@/assets/img/icon-diamond.svg"
                                                              alt="diamond"></div>
          </nuxt-link>
        </swiper-slide>

        <!-- If we need navigation buttons -->
        <div class="navigation-left" v-if="list.length > 6">
          <img src="@/assets/img/icon-left-small.svg" alt="left">
        </div>
        <div class="navigation-right" v-if="list.length > 6">
          <img src="@/assets/img/icon-right-small.svg" alt="right">
        </div>
      </swiper>
    </div>
    <div class="btn-container" v-if="btnViewAllVisible">
      <nuxt-link :to="btnViewAllLink" class="btn-secondary">
        view all
      </nuxt-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-carousel-count-6-mobile {
  padding: 10px 16px 10px 16px;
  margin-top: 16px;

  .title {
    /* Headline/small */
    font-family: "Alternate Gothic No3 D";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    text-transform: uppercase;
    text-align: center;
  }

  .carousel {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-top: 10px;

    .item {
      flex-shrink: 0;
      margin-right: 10px;
      cursor: pointer;
      width: 37.3vw;
      height: 69.9vw;

      .item-top {
        width: 100%;
        height: 52.3vw;
        background: center center no-repeat;
        background-size: cover;
        position: relative;
        display: flex;
        justify-content: flex-end;
        padding: 2px;

        .like {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 32px;
          height: 32px;

          img {
            pointer-events: none; // 使其不被点击，直接穿透到父级去
            width: 24px;
            height: 24px;
          }
        }
      }

      .item-bottom {
        width: 100%;
        height: 74px;
        padding: 8px 8px 12px 8px;

        div {
          pointer-events: none; // 使其不被点击，直接穿透到父级去
        }

        .brand {
          /* Text xs/Bold */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 650;
          line-height: 18px; /* 150% */
        }

        .name {
          /* Text xs/Regular */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 18px; /* 150% */

          // 单行
          word-break: break-all;
          word-wrap: anywhere;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          line-clamp: 1;
          -webkit-line-clamp: 1;
        }

        .price {
          /* Text xs/Regular */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 18px; /* 150% */

          display: flex;
          align-items: center;
          gap: 2px;

          .premium {
            width: 18px;
            height: 18px;
            flex-shrink: 0;
          }
        }
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .btn-secondary {
    width: 144px;
    margin: 10px auto;
  }
}

.module-carousel-count-6-desktop {
  margin-top: 16px;
  padding-top: 10px;
  padding-bottom: 10px;

  .title {
    padding-top: 18px;
    width: 100%;
    min-width: $content-1280-width;
    text-align: center;

    font-family: "Alternate Gothic No3 D";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    text-transform: uppercase;
  }

  .carousel {
    margin-top: 10px;

    .swiper {
      width: $content-1280-width;
      position: relative;

      .swiper-slide {
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;

        .slide-top {
          width: fit-content;
          height: fit-content;
          display: flex;
          justify-content: flex-end;
          position: relative;

          cursor: pointer;

          .cover-img {
            pointer-events: none; // 使其不被点击，直接穿透到父级去
            width: 205px;
            height: 286px;
            object-fit: cover;
            object-position: center center;
          }

          .cover-video {
            pointer-events: none; // 使其不被点击，直接穿透到父级去
            width: 205px;
            height: 286px;
            object-fit: cover;
            object-position: center center;
          }

          .like {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 2.67px;
            top: 2.67px;

            width: 42.67px;
            height: 42.67px;

            img {
              pointer-events: none; // 使其不被点击，直接穿透到父级去
              width: 32px;
              height: 32px;
            }
          }
        }

        .slide-bottom {
          width: 100%;
          height: 88px;
          padding: 10.67px 10.67px 5.33px 10.67px;
          cursor: pointer;

          div {
            pointer-events: none; // 使其不被点击，直接穿透到父级去
          }

          &:hover {
            text-decoration: underline;
          }

          .brand {
            width: fit-content;
            text-overflow: ellipsis;
            font-family: "TWK Lausanne";
            font-size: 16px;
            font-style: normal;
            font-weight: 650;
            line-height: 24px; /* 150% */
          }

          .name {
            width: fit-content;
            text-overflow: ellipsis;
            font-family: "TWK Lausanne";
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 24px; /* 150% */

            // 单行
            word-break: break-all;
            word-wrap: anywhere;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            line-clamp: 1;
            -webkit-line-clamp: 1;
          }

          .price {
            width: fit-content;
            text-align: left;
            text-overflow: ellipsis;
            font-family: "TWK Lausanne";
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 24px; /* 150% */

            display: flex;
            align-items: center;
            gap: 2px;

            .premium {
              width: 18px;
              height: 18px;
              flex-shrink: 0;
            }
          }

        }
      }

      .navigation-left {
        position: absolute;
        display: flex;
        left: 0;
        top: 50%;
        z-index: 1;
        transform: translate(0, -150%);
        cursor: pointer;

        img {
          width: 40px;
          height: 40px;
        }
      }

      .navigation-right {
        position: absolute;
        display: flex;
        right: 0;
        top: 50%;
        z-index: 1;
        transform: translate(0, -150%);
        cursor: pointer;

        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .btn-container {
    width: 100%;
    min-width: $content-1280-width;
    margin-top: 10px;

    .btn-secondary {
      width: 144px;
      margin: 0 auto;
    }
  }
}
</style>
